<template>
  <div class="flex gap-5">
    <div class="flex flex-col justify-center items-center gap-3">
      <img :src="require(`@/assets/${avatar}`)"
        style="width:100px;height:90px"
        class="rounded-md"
      />
      <div class="text-center uppercase leading-6 flex flex-col justify-center whitespace-nowrap gap-1">
        <span class="font-black text-xs text-darkPurple">{{ name }}</span>
        <span class="font-semibold text-xs text-jet">{{ designation }}</span>
        <span class="font-semibold text-xs text-romanSilver">{{  department }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AvatarDetails",
    props: {
      name: {
        type: String,
        required: false,
        default: 'Employee Name'
      },
      designation: {
        type: String,
        required: false,
        default: 'Designation'
      },
      department: {
        type: String,
        required: false,
        default: 'Department'
      },
      avatar: {
        type: String,
        required: false,
        default: 'male-user.png'
      },
    },
  };
  </script>